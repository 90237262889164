import * as React from 'react';
import Layout from '../components/Layout';
import { Container, Card } from '@ao-internal/components-react';

export const NotFound404: React.SFC<{}> = () => (
	<Layout>
		<Container className="blog-page">
			<Card>
				<h1 className="text-display text-center mb-8 blog-page__header">
					NOT FOUND
				</h1>
				<p>You just hit a page that doesn&#39;t exist... the sadness.</p>
			</Card>
		</Container>
	</Layout>
);

export default NotFound404;
